import React from "react"
import { Link } from "gatsby"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share"

import Source from "./Source"

const CardPost = ({ card }) => {
  return (
    <div style={{ width: "100%" }}>
      <div className="card-featured-slider">
        <div
          className="card-featured-slider-container"
          style={{
            backgroundImage: `linear-gradient(rgba(245, 246, 252, 0.52), rgb(0 0 0 / 83%)), url(${card.imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="card-featured-slider-content">
            <Link
              to={`/pt/topics/${card.topic.slug}`}
              style={{ backgroundColor: card.topic.color }}
            >
              {card.topic.name}
            </Link>
            <h1>{card.name}</h1>
          </div>
        </div>
      </div>

      <div
        className="card-internal-share card-mobile-internal-share"
        style={{ marginTop: 20, marginBottom: -20 }}
      >
        <FacebookShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          quote={`${card.name} e outros conteúdos estão no Nizam`}
          hashtag="Nizam"
        >
          <FacebookIcon size={50} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
          via="Nizam"
          hashtags={["Nizam"]}
        >
          <TwitterIcon size={50} round />
        </TwitterShareButton>

        <WhatsappShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
          separator=" - "
        >
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>

        <TelegramShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
        >
          <TelegramIcon size={50} round />
        </TelegramShareButton>
      </div>

      <div className="card-internal" style={{ marginTop: 40 }}>
        <div className="card-internal-hashtags">
          {card.tags.map(tag => (
            <Link
              key={tag.slug}
              to={`/pt/hashtags/${tag.slug}`}
              style={{ marginRight: 10 }}
            >
              {tag.name}
            </Link>
          ))}
        </div>

        <Source source={card.source} />

        {card.type === "text" || card.type === "quote" ? (
          <div
            className="card-internal-content"
            dangerouslySetInnerHTML={{
              __html: card.meta.content,
            }}
          ></div>
        ) : (
          card.meta.listFields.map(listItem => (
            <div key={listItem.title}>
              <h2>{listItem.title}</h2>
              <div
                className="card-internal-content"
                dangerouslySetInnerHTML={{
                  __html: `${listItem.content ? listItem.content : null}`,
                }}
              ></div>
            </div>
          ))
        )}

        <div className="card-internal-hashtags" style={{ paddingBottom: 5 }}>
          {card.tags.map(tag => (
            <Link
              key={tag.slug}
              to={`/pt/hashtags/${tag.slug}`}
              style={{ marginRight: 10 }}
            >
              {tag.name}
            </Link>
          ))}
        </div>
      </div>

      <div
        className="card-internal-share card-mobile-internal-share"
        style={{ marginTop: -20, marginBottom: 0 }}
      >
        <FacebookShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          quote={`${card.name} e outros conteúdos estão no Nizam`}
          hashtag="Nizam"
        >
          <FacebookIcon size={50} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
          via="Nizam"
          hashtags={["Nizam"]}
        >
          <TwitterIcon size={50} round />
        </TwitterShareButton>

        <WhatsappShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
          separator=" - "
        >
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>

        <TelegramShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
        >
          <TelegramIcon size={50} round />
        </TelegramShareButton>
      </div>
    </div>
  )
}

export default CardPost
