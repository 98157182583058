import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

const ReadMoreCard = ({ card }) => {
  const [cardContent, setCardContent] = useState("")

  useEffect(() => {
    if (card.node.meta.content) {
      let localContent = document.createElement("div")
      localContent.innerHTML = card.node.meta.content

      localContent =
        localContent.innerText
          .replace(/(<([^>]+)>)/gi, "")
          .trim()
          .substring(0, 80) + "..."

      setCardContent(localContent)
    } else {
      let localContent = document.createElement("div")
      localContent.innerHTML = card.node.meta.excerpt

      localContent =
        localContent.innerText
          .replace(/(<([^>]+)>)/gi, "")
          .trim()
          .substring(0, 80) + "..."

      setCardContent(localContent)
    }
  }, [])

  return (
    <div
      key={card.node.slug}
      onClick={() => navigate(`/pt/cards/${card.node.slug}`)}
      className="card-internal-read-more-list-item"
      style={{
        display: "grid",
        gridTemplateColumns: "0.4fr 0.7fr",
        cursor: "pointer",
      }}
    >
      <div
        className="collection-placer"
        style={{
          width: 140,
          height: 140,
          backgroundImage: `url(${card.node.imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "10px",
        }}
      ></div>

      <div className="collection-info">
        <h3>{card.node.name}</h3>
        <div
          className="card-internal-read-more-list-item-excerpt"
          dangerouslySetInnerHTML={{
            __html: cardContent,
          }}
        ></div>
      </div>
    </div>
  )
}

export default ReadMoreCard
