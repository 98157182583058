import React, { useEffect, useState } from "react"
import { Link, graphql, navigate } from "gatsby"

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share"

import Layout from "../../components/Layout"
import CardPost from "../../components/CardPost"
import CardQuote from "../../components/CardQuote"
import ReadMoreCard from "../../components/ReadMoreCard"
import Breadcrumbs from "../../components/Breadcrumbs"
import SEO from "../../components/SEO"

const CardTemplate = ({ data }) => {
  const card = data.cardsPt
  const cardsRandom = data.allCardsRandomPt.edges
    .sort(() => 0.5 - Math.random())
    .slice(0, 4)

  let metaDescription = card.meta.content || card.meta.excerpt
  metaDescription = metaDescription.replace(/(<([^>]+)>)/gi, "").trim()

  if (metaDescription.length >= 120) {
    metaDescription = metaDescription.substring(0, 120)
  }

  return (
    <Layout>
      <SEO
        title={card.name}
        description={metaDescription}
        card={true}
        image={card.imageUrl}
        canonical={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
        category={card.topic.name}
        date={card.createdAt}
        dateUpdated={card.updatedAt}
      />

      <div
        className="main-content card-top-main-content"
        style={{ marginTop: 117, marginBottom: 0 }}
      >
        <Breadcrumbs
          secondaryLink={`/pt/topics/${card.topic.slug}`}
          secondaryLinkName={card.topic.name}
          currentPage={card.name}
          style={{ marginTop: 0, marginBottom: 30 }}
        />
      </div>

      <div
        className="main-content card-internal-main-content"
        style={{ marginTop: 0 }}
      >
        <div className="card-internal-share card-desktop-internal-share">
          <FacebookShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
            quote={`${card.name} e outros conteúdos estão no Nizam`}
            hashtag="Nizam"
          >
            <FacebookIcon size={50} round />
          </FacebookShareButton>

          <TwitterShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
            title={`${card.name} e outros conteúdos estão no Nizam`}
            via="Nizam"
            hashtags={["Nizam"]}
          >
            <TwitterIcon size={50} round />
          </TwitterShareButton>

          <WhatsappShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
            title={`${card.name} e outros conteúdos estão no Nizam`}
            separator=" - "
          >
            <WhatsappIcon size={50} round />
          </WhatsappShareButton>

          <TelegramShareButton
            url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
            title={`${card.name} e outros conteúdos estão no Nizam`}
          >
            <TelegramIcon size={50} round />
          </TelegramShareButton>
        </div>

        {card.type === "quote" ? (
          <CardQuote card={card} />
        ) : (
          <CardPost card={card} />
        )}
      </div>

      <div className="main-content" style={{ marginTop: 50 }}>
        <div className="card-internal-read-more">
          <h2>Leia também...</h2>

          <div className="card-internal-read-more-list">
            {cardsRandom.map(card => (
              <ReadMoreCard card={card} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CardTemplate

export const query = graphql`
  query($id: String!) {
    cardsPt(id: { eq: $id }) {
      name
      slug
      createdAt
      updatedAt
      imageUrl
      type
      source {
        name
        url
        sponsored
        type {
          name
          type
        }
      }
      topic {
        name
        slug
        color
      }
      tags {
        name
        slug
      }
      meta {
        content
        excerpt
        author
        listFields {
          title
          content
        }
      }
    }

    allCardsRandomPt(limit: 180) {
      edges {
        node {
          name
          slug
          imageUrl
          meta {
            content
            excerpt
          }
        }
      }
    }
  }
`
