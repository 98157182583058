import React from "react"
import { Link } from "gatsby"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share"

import QuoteIcon from "../images/icons/Quote"

import Source from "./Source"

const CardQuote = ({ card }) => {
  return (
    <div style={{ width: "100%" }}>
      <div className="card-internal-share card-mobile-internal-share card-mobile-internal-share-quote">
        <FacebookShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          quote={`${card.name} e outros conteúdos estão no Nizam`}
          hashtag="Nizam"
        >
          <FacebookIcon size={50} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
          via="Nizam"
          hashtags={["Nizam"]}
        >
          <TwitterIcon size={50} round />
        </TwitterShareButton>

        <WhatsappShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
          separator=" - "
        >
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>

        <TelegramShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
        >
          <TelegramIcon size={50} round />
        </TelegramShareButton>
      </div>

      <div className="card-internal card-internal-quote">
        <div className="card-internal-info">
          <div className="card-internal-quote-icon">
            <QuoteIcon />
          </div>

          <div
            className="card-internal-img"
            style={{
              backgroundImage: `url(${card.imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: 240,
              height: 240,
            }}
          ></div>

          <div style={{ textAlign: "center", marginBottom: 20 }}>
            <h1 style={{ marginBottom: 15 }}>{card.name}</h1>
          </div>

          <Link
            to={`/pt/topics/${card.topic.slug}`}
            style={{ backgroundColor: card.topic.color }}
          >
            {card.topic.name}
          </Link>
        </div>

        <Source source={card.source} />

        {card.type === "text" || card.type === "quote" ? (
          <div
            className="card-internal-content"
            dangerouslySetInnerHTML={{
              __html: card.meta.content,
            }}
          ></div>
        ) : (
          card.meta.listFields.map(listItem => (
            <div>
              <h2>{listItem.title}</h2>
              <div
                className="card-internal-content"
                dangerouslySetInnerHTML={{
                  __html: `${listItem.content ? listItem.content : null}`,
                }}
              ></div>
            </div>
          ))
        )}

        <div
          className="card-internal-info"
          style={{ textAlign: "center", marginBottom: 30 }}
        >
          <small
            style={{
              textTransform: "uppercase",
              fontWeight: 500,
              color: "#103D45",
              fontSize: 16,
            }}
          >
            - {card.meta.author}
          </small>
        </div>

        <div className="card-internal-hashtags" style={{ paddingBottom: 5 }}>
          {card.tags.map(tag => (
            <Link to={`/pt/hashtags/${tag.slug}`} style={{ marginRight: 10 }}>
              {tag.name}
            </Link>
          ))}
        </div>
      </div>

      <div className="card-internal-share card-mobile-internal-share card-mobile-internal-share-quote">
        <FacebookShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          quote={`${card.name} e outros conteúdos estão no Nizam`}
          hashtag="Nizam"
        >
          <FacebookIcon size={50} round />
        </FacebookShareButton>

        <TwitterShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
          via="Nizam"
          hashtags={["Nizam"]}
        >
          <TwitterIcon size={50} round />
        </TwitterShareButton>

        <WhatsappShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
          separator=" - "
        >
          <WhatsappIcon size={50} round />
        </WhatsappShareButton>

        <TelegramShareButton
          url={`${process.env.GATSBY_HOST_URL}/pt/cards/${card.slug}`}
          title={`${card.name} e outros conteúdos estão no Nizam`}
        >
          <TelegramIcon size={50} round />
        </TelegramShareButton>
      </div>
    </div>
  )
}

export default CardQuote
